import React from "react";
import DashboardDrawer from "../../../../components/src/DashboardDrawer";
// Customizable Area Start
// istanbul ignore file
import {
    Box,
    Grid,
    Typography,
    IconButton,
    Toolbar,
    Button,
    AppBar,
    MenuItem,
    Select,
    FormControl,
    TextField,
    Container,
    FormHelperText
} from "@material-ui/core"
import DeleteIcon from '@material-ui/icons/Delete';
import MenuIcon from '@material-ui/icons/Menu';
import AddNewCoursesController, { Props } from "./AddNewCoursesController";
import { palette } from "../../../../components/src/theme";
import { withTheme, withStyles, createStyles, Theme } from "@material-ui/core/styles";
import DashboardSetting from "../../../../components/src/DashboardSetting";
import AlertModal from "../../../../components/src/AlertModal";
// @ts-ignore
import AddInstructorDropZone from '../../../../components/src/AddInstructorDropZone';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import CustomLoader from "../../../../components/src/CustomLoader";
const drawerWidth = 240;
const userType = typeof window !== 'undefined' ? window.localStorage.getItem('userType') : null;
const userName = typeof window !== 'undefined' ? window.localStorage.getItem('userName') : null;

// Customizable Area End

class AddNewCourses extends AddNewCoursesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen })
    }

    getBold = (value: any) => {
        this.setState({
            bold: value
        })
    }

    getUnderlined = (value: any) => {
        this.setState({
            underlined: value
        })
    }

    getItalic = (value: any) => {
        this.setState({
            italic: value
        })
    }
    getAlignment = (value: any) => {
        this.setState({
            alignment: value
        })
    }
    showInstructorForm = () => {
        this.setState({
            enableInstructorForm: !this.state.enableInstructorForm
        })
    }

    handleChangeCoInstructor = (event: any) => {
        this.setState({
            coInstructorName: event?.target?.value
        })
    }

    changeMainInstructor = (e: any) => {
        this.setState({
            selectedMainInstructor: e?.target?.value
        })
    }

    addNewQuestions = (pId: any) => {
        // @ts-ignore
        let oldLessons = _.cloneDeep(this.state.lessonList)
        this.state.lessonList.map((item: any, index: any) => {
            if(item.id == pId){
                oldLessons[index].questions_attributes = [...oldLessons[index].questions_attributes, {
                    id:  new Date().getTime(),
                    question: '',
                    description: '',
                    option1: '',
                    option2: '',
                    option3: '',
                    option4: '',
                    correct_answer: ''
                }]

                this.setState({
                    lessonList: oldLessons
                })
            }
        })
    }

    addTranscript = (pId: any) => {
        // @ts-ignore
        let oldLessons = _.cloneDeep(this.state.lessonList)
        this.state.lessonList.map((item: any, index: any) => {
            if(item.id == pId){
                oldLessons[index].transcript = [...oldLessons[index].transcript, {
                    id:  new Date().getTime(),
                    time: '',
                    value: ''
                }]

                this.setState({
                    lessonList: oldLessons
                })
            }
        })
    }

    addStamp = (pId: any) => {
        // @ts-ignore
        let oldLessons = _.cloneDeep(this.state.lessonList)
        this.state.lessonList.map((item: any, index: any) => {
            if(item.id == pId){
                oldLessons[index].timestamp = [...oldLessons[index].timestamp, {
                    id:  new Date().getTime(),
                    time: '',
                    value: ''
                }]

                this.setState({
                    lessonList: oldLessons
                })
            }
        })
    }

    removeTranscript = (pId: any) => {
        // @ts-ignore
        let oldLessons = _.cloneDeep(this.state.lessonList)
        this.state.lessonList.map((item: any, index: any) => {
            if(item.id == pId){
                oldLessons[index].transcript.splice(oldLessons[index].transcript.length -1, 1);
                this.setState({
                    lessonList: oldLessons
                })
            }
        })
    }

    removeStamp = (pId: any) => {
        // @ts-ignore
        let oldLessons = _.cloneDeep(this.state.lessonList)
        this.state.lessonList.map((item: any, index: any) => {
            if(item.id == pId){
                oldLessons[index].timestamp.splice(oldLessons[index].timestamp.length -1, 1);
                this.setState({
                    lessonList: oldLessons
                })
            }
        })
    }

    removeNewQuestions = (pId: any) => {
        // @ts-ignore
        let oldLessons = _.cloneDeep(this.state.lessonList)
        this.state.lessonList.map((item: any, index: any) => {
            if(item.id == pId){
                oldLessons[index].questions_attributes.splice(oldLessons[index].questions_attributes.length -1, 1);
                this.setState({
                    lessonList: oldLessons
                })
            }
        })
    }

    addNewFaqs = (pId: any) => {
        // @ts-ignore
        let oldLessons = _.cloneDeep(this.state.lessonList)
        this.state.lessonList.map((item: any, index: any) => {
            if(item.id == pId){
                oldLessons[index].faqs = [...oldLessons[index].faqs, {
                    id:  new Date().getTime(),
                    question: '',
                    answer: ''
                }]

                this.setState({
                    lessonList: oldLessons
                })
            }
        })
    }

    addNewTodos = (pId: any) => {
        // @ts-ignore
        let oldLessons = _.cloneDeep(this.state.lessonList)
        this.state.lessonList.map((item: any, index: any) => {
            if(item.id == pId){
                oldLessons[index].todos = [...oldLessons[index].todos, {
                    id:  new Date().getTime(),
                    title: '',
                    description: ''
                }]

                this.setState({
                    lessonList: oldLessons
                })
            }
        })
    }

    removeNewTodos = (pId: any) => {
        // @ts-ignore
        let oldLessons = _.cloneDeep(this.state.lessonList)
        this.state.lessonList.map((item: any, index: any) => {
            if(item.id == pId){
                oldLessons[index].todos.splice(oldLessons[index].todos.length -1, 1);
                this.setState({
                    lessonList: oldLessons
                })
            }
        })
    }

    removeNewFaqs = (pId: any) => {
        // @ts-ignore
        let oldLessons = _.cloneDeep(this.state.lessonList)
        this.state.lessonList.map((item: any, index: any) => {
            if(item.id == pId){
                oldLessons[index].faqs.splice(oldLessons[index].faqs.length -1, 1);
                this.setState({
                    lessonList: oldLessons
                })
            }
        })
    }

    addNewLessonForm = () => {
        this.setState((prev_state) => {
            return {
                lessonList: [...prev_state.lessonList, {
                    id: new Date().getTime(),
                    title: '',
                    description: '',
                    video: '',
                    faqs: [{
                        id: new Date().getTime(),
                        question: '',
                        answer: ''
                    }],
                    todos: [{
                        id: new Date().getTime(),
                        title: '',
                        desciption: ''
                    }], 
                    questions_attributes: [{
                        id: new Date().getTime(),
                        question: '',
                        description: '',
                        option1: '',
                        option2: '',
                        option3: '',
                        option4: '',
                        correct_answer: ''
                    }],
                    transcript: [{
                        id: new Date().getTime(),
                        time: '',
                        value: ''
                    }],
                    timestamp: [{
                        id: new Date().getTime(),
                        time: '',
                        value: ''
                    }],
                    material_details_attributes: [{
                        title: '',
                        summary: '',
                        description: '',
                        material_file: ''
                    }]
                }]
            }
        })
    }

    removeAdditionalForm = (id: any) => {
        const newLessonList = this.state.lessonList.filter((lesson: any) => lesson.id !== id)
        this.setState({ lessonList: newLessonList })
    }

    handleCourseChange = (e: any, type: any) => {
        if(type == 'courseTitle'){
            this.setState({
                courseTitle: e?.target?.value
            })
        } else if(type == "coursePrice"){
            this.setState({
                coursePrice: e?.target?.value
            })
        } else {
            this.setState({
                courseDesc: e?.target?.value
            })
        }
    }

    coInstructorDetails = () => {
        return (
            this.state.coInstructorName ? this.state.coInstructorName.map((item: any) => {
                let filtered = this.state.coInstructorList.length && this.state.coInstructorList.filter((i: any) => {
                    return item == i.id
                })
                return(
                    filtered.map((name: any)=>{
                        return(
                            <div
                                style={{
                                    borderRadius: '8px',
                                    border: '1px solid rgba(82,92, 229, 0.12)',
                                    padding: '40px 48px',
                                    marginBottom: '15px'
                                }}
                            >
                                <div>
                                    <img 
                                        style={{
                                            height: '184px',
                                            width: '184px',
                                            borderRadius: '9px'
                                        }}
                                        src={name.attributes.profile_image}
                                    />
                                </div>
                                <div>
                                    <div
                                        style={{
                                            borderRadius: '8px',
                                            border: '1px solid rgba(82,92, 229, 0.12)',
                                            padding: '16px 16px 18px 18px',
                                            marginTop: '15px',
                                            height: '56px'
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: '16px',
                                                fontFamily: 'Poppins-SemiBold'
                                            }}
                                        >
                                            {name.attributes.name}
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            borderRadius: '8px',
                                            border: '1px solid rgba(82,92, 229, 0.12)',
                                            padding: '20px 24px 30px',
                                            marginTop: '15px',
                                            minHeight: '250px'
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: '16px',
                                                fontFamily: 'Poppins-SemiBold'
                                            }}
                                        >
                                            {name.attributes.about}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                )
            })
            : null
        )
    }

    getLessonDetailsForm = (item: any, inputError: any) => {
        return (
            <div
                style={{
                    marginTop: '15px'
                }}
            >
                <TextField
                    fullWidth
                    name="title"
                    value={item.title}
                    onChange={(e) => this.handleLessonChange(item.id, e)}
                    InputProps={{
                        // className: classes.input,
                        disableUnderline: true,
                        style:{
                            height: '88px',
                            borderRadius: '9px',
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            padding: '16px 36px 16px 24px',
                            fontSize: "20px",
                            fontFamily: "Poppins-Light",
                        }
                    }}
                    style={{
                        fontSize: "20px",
                        fontFamily: "Poppins-Light",
                        marginTop: "10px",
                    }}
                    placeholder="Title Of The Lesson*"
                    error={inputError?.lessonTitle === undefined || inputError?.lessonTitle === "" ? false : true}
                    helperText={inputError.lessonTitle}
                />
                <TextField
                    name="description"
                    style={{ fontSize: "16px", fontFamily: "Poppins-Light", paddingTop: "10px" }}
                    multiline={true}
                    minRows={15}
                    fullWidth
                    value={item.description}
                    onChange={(e) => this.handleLessonChange(item.id, e)}
                    inputProps={{ 
                        maxLength: 1250 ,
                        style:{
                            height: '200px',
                            borderRadius: '9px',
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            padding: '16px 36px 16px 24px',
                            fontSize: "20px",
                            fontFamily: "Poppins-Light",
                        }
                    }}
                    placeholder="Summary Of The Lesson*"
                    InputProps={{
                        // className: classes.input,
                        disableUnderline: true
                    }}
                    error={inputError?.lessonDescription === undefined || inputError?.lessonDescription === "" ? false : true}
                    helperText={inputError.lessonDescription}
                />
                <AddInstructorDropZone 
                    error={inputError.lessonVideo}
                    type={'video'}
                    addInstructorProfile = {(acceptedFiles: any) => this.addLessonVideo(item.id, acceptedFiles)}
                />
            </div>
        )
    }

    addRemoveTrans = (item: any) => {
        return (
            <>
                {
                    item?.transcript?.length < 2 ?
                        <IconButton 
                            style={{
                                background: '#8991a4',
                                right: '0px',
                                marginRight: '-30px'
                            }}
                            onClick={() => this.addTranscript(item.id)}
                        >
                            <img 
                                height={30}
                                width={30}
                                src={require('../../assets/add.png')}
                            />
                        </IconButton>
                    :
                    <>
                        <IconButton 
                            style={{
                                background: '#8991a4',
                                right: '0px',
                                marginRight: '-30px',
                                padding: '12px 15px'
                            }}
                            onClick={() => this.removeTranscript(item.id)}
                        >
                            <RemoveRoundedIcon 
                                style={{color: '#fff'}}
                            />
                        </IconButton>
                        <IconButton 
                            style={{
                                background: '#8991a4',
                                right: '0px',
                                marginRight: '-30px'
                            }}
                            onClick={() => this.addTranscript(item.id)}
                        >
                            <img 
                                height={30}
                                width={30}
                                src={require('../../assets/add.png')}
                            />
                        </IconButton>
                    </>
                }
            </>
        )
    }

    getTranscriptForm = (tItem: any, index: any, inputError: any, item: any) => {
        return (
            <div
                style={{
                    display:'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    marginTop: '15px'
                }}
                key={index}
            >
                <TextField 
                    placeholder="0:00"
                    name={'time'}
                    value={tItem.time}
                    onChange={(e: any) => this.handleTransChange(tItem.id, e, item.id)}
                    InputProps={{
                        disableUnderline: true,
                        style:{
                            height: '36px',
                            width: '100px',
                            borderRadius: '7px',
                            border: '1px solid #e6e8f0',
                            padding: '16px 36px 16px 24px',
                            fontSize: "16px",
                            fontFamily: "Poppins-Light",
                            color: '#353535',
                            marginTop: '7px'
                        }
                    }}
                    error={inputError?.tTime === undefined || inputError?.tTime === "" ? false : true}
                    helperText={inputError.tTime}
                />
                <div
                    style={{
                        marginLeft: '30px',
                        width: '100%'
                    }}
                >
                    <TextField
                        fullWidth
                        name={'value'}
                        value={tItem.value}
                        onChange={(e: any) => this.handleTransChange(tItem.id, e, item.id)}
                        InputProps={{
                            // className: classes.input,
                            disableUnderline: true,
                            style:{
                                height: '48px',
                                borderRadius: '9px',
                                border: '1px solid #e6e8f0',
                                padding: '16px 36px 16px 24px',
                                fontSize: "16px",
                                fontFamily: "Poppins-Light",
                            }
                        }}
                        style={{
                            fontSize: "16px",
                            fontFamily: "Poppins-Light",
                            marginTop: "10px",
                        }}
                        placeholder="Transcript*"
                        error={inputError?.tDescription === undefined || inputError?.tDescription === "" ? false : true}
                        helperText={inputError.tDescription}
                    />
                </div>
            </div>
        )
    }

    getStampForm = (sItem: any, index: any, inputError: any, item: any) => {
        return (
            <div
                style={{
                    display:'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    marginTop: '15px'
                }}
                key={index}
            >
                <TextField 
                    placeholder="0:00"
                    name={'time'}
                    value={sItem.time}
                    onChange={(e: any) => this.handleStampChange(sItem.id, e, item.id)}
                    InputProps={{
                        disableUnderline: true,
                        style:{
                            height: '36px',
                            width: '100px',
                            borderRadius: '7px',
                            border: '1px solid #e6e8f0',
                            padding: '16px 36px 16px 24px',
                            fontSize: "16px",
                            fontFamily: "Poppins-Light",
                            color: '#353535',
                            marginTop: '7px'
                        }
                    }}
                    error={inputError?.sTime === undefined || inputError?.sTime === "" ? false : true}
                    helperText={inputError?.sTime}
                />
                <div
                    style={{
                        marginLeft: '30px',
                        width: '100%'
                    }}
                >
                    <TextField
                        fullWidth
                        name={'value'}
                        value={sItem.value}
                        onChange={(e: any) => this.handleStampChange(sItem.id, e, item.id)}
                        InputProps={{
                            // className: classes.input,
                            disableUnderline: true,
                            style:{
                                height: '48px',
                                borderRadius: '9px',
                                border: '1px solid #e6e8f0',
                                padding: '16px 36px 16px 24px',
                                fontSize: "16px",
                                fontFamily: "Poppins-Light",
                            }
                        }}
                        style={{
                            fontSize: "16px",
                            fontFamily: "Poppins-Light",
                            marginTop: "10px",
                        }}
                        placeholder="Description*"
                        error={inputError?.sDescription === undefined || inputError?.sDescription === "" ? false : true}
                        helperText={inputError?.sDescription}
                    />
                </div>
            </div>
        )
    }

    // getStampForm = (item: any, inputError: any, ) => {
    //     return (
    //         item?.timestamp?.length && item.timestamp.map((sItem: any, index: any) => {
    //             return (
    //                 <div
    //                     style={{
    //                         display:'flex',
    //                         justifyContent: 'flex-start',
    //                         alignItems: 'center',
    //                         marginTop: '15px'
    //                     }}
    //                     key={index}
    //                 >
    //                     <TextField 
    //                         placeholder="0:00"
    //                         name={'time'}
    //                         value={sItem.time}
    //                         onChange={(e: any) => this.handleStampChange(sItem.id, e, item.id)}
    //                         InputProps={{
    //                             disableUnderline: true,
    //                             style:{
    //                                 height: '36px',
    //                                 width: '100px',
    //                                 borderRadius: '7px',
    //                                 border: '1px solid #e6e8f0',
    //                                 padding: '16px 36px 16px 24px',
    //                                 fontSize: "16px",
    //                                 fontFamily: "Poppins-Light",
    //                                 color: '#353535',
    //                                 marginTop: '7px'
    //                             }
    //                         }}
    //                         error={inputError?.sTime === undefined || inputError?.sTime === "" ? false : true}
    //                         helperText={inputError.sTime}
    //                     />
    //                     <div
    //                         style={{
    //                             marginLeft: '30px',
    //                             width: '100%'
    //                         }}
    //                     >
    //                         <TextField
    //                             fullWidth
    //                             name={'value'}
    //                             value={sItem.value}
    //                             onChange={(e: any) => this.handleStampChange(sItem.id, e, item.id)}
    //                             InputProps={{
    //                                 // className: classes.input,
    //                                 disableUnderline: true,
    //                                 style:{
    //                                     height: '48px',
    //                                     borderRadius: '9px',
    //                                     border: '1px solid #e6e8f0',
    //                                     padding: '16px 36px 16px 24px',
    //                                     fontSize: "16px",
    //                                     fontFamily: "Poppins-Light",
    //                                 }
    //                             }}
    //                             style={{
    //                                 fontSize: "16px",
    //                                 fontFamily: "Poppins-Light",
    //                                 marginTop: "10px",
    //                             }}
    //                             placeholder="Timestamp*"
    //                             error={inputError?.sDescription === undefined || inputError?.sDescription === "" ? false : true}
    //                             helperText={inputError.sDescription}
    //                         />
    //                     </div>
    //                 </div>
    //             )
    //         })
    //     )
    // }

    addRemoveStamp = (item: any) => {
        return(
            <>
                {
                    item?.timestamp?.length < 2 ?
                        <IconButton 
                            style={{
                                background: '#8991a4',
                                right: '0px',
                                marginRight: '-30px'
                            }}
                            onClick={() => this.addStamp(item.id)}
                        >
                            <img 
                                height={30}
                                width={30}
                                src={require('../../assets/add.png')}
                            />
                        </IconButton>
                    :
                    <>
                        <IconButton 
                            style={{
                                background: '#8991a4',
                                right: '0px',
                                marginRight: '-30px',
                                padding: '12px 15px'
                            }}
                            onClick={() => this.removeStamp(item.id)}
                        >
                            <RemoveRoundedIcon 
                                style={{color: '#fff'}}
                            />
                        </IconButton>
                        <IconButton 
                            style={{
                                background: '#8991a4',
                                right: '0px',
                                marginRight: '-30px'
                            }}
                            onClick={() => this.addStamp(item.id)}
                        >
                            <img 
                                height={30}
                                width={30}
                                src={require('../../assets/add.png')}
                            />
                        </IconButton>
                    </>
                }
            </>
        )
    }

    getLessonMaterialForm = (item: any, inputError: any) => {
        return(
            item?.material_details_attributes?.length && item.material_details_attributes.map((mItem: any, i: any) => {
                return (
                    <div
                        style={{
                            marginTop: '15px'
                        }}
                    >
                        <TextField
                            fullWidth
                            name="title"
                            value={mItem.title}
                            onChange={(e) => this.handleMaterialChange(mItem.id, e, item.id)}
                            InputProps={{
                                // className: classes.input,
                                disableUnderline: true,
                                style:{
                                    height: '64px',
                                    borderRadius: '9px',
                                    border: '1px solid #e6e8f0',
                                    padding: '4px 36px 4px 24px',
                                    fontSize: "20px",
                                    fontFamily: "Poppins-Light",
                                    color: '#353535'
                                }
                            }}
                            style={{
                                fontSize: "20px",
                                fontFamily: "Poppins-Light",
                                marginTop: "10px",
                            }}
                            placeholder="Title Of The Material*"
                            error={inputError?.mTitle === undefined || inputError?.mTitle === "" ? false : true}
                            helperText={inputError.mTitle}
                        />
                        <TextField
                            fullWidth
                            name="description"
                            value={mItem.description}
                            onChange={(e) => this.handleMaterialChange(mItem.id, e, item.id)}
                            InputProps={{
                                // className: classes.input,
                                disableUnderline: true,
                                style:{
                                    height: '64px',
                                    borderRadius: '9px',
                                    border: '1px solid #e6e8f0',
                                    padding: '4px 36px 4px 24px',
                                    fontSize: "20px",
                                    fontFamily: "Poppins-Light",
                                    color: '#353535'
                                }
                            }}
                            style={{
                                fontSize: "20px",
                                fontFamily: "Poppins-Light",
                                marginTop: "10px",
                            }}
                            placeholder="Subtitle Of The Material*"
                            error={inputError?.mDescription === undefined || inputError?.mDescription === "" ? false : true}
                            helperText={inputError.mDescription}
                        />
                        <TextField
                            name="summary"
                            style={{ fontSize: "16px", fontFamily: "Poppins-Light", paddingTop: "10px" }}
                            multiline={true}
                            minRows={15}
                            fullWidth
                            value={mItem.summary}
                            onChange={(e) => this.handleMaterialChange(mItem.id, e, item.id)}
                            inputProps={{ 
                                maxLength: 1250 ,
                                style:{
                                    height: '200px',
                                    borderRadius: '9px',
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    padding: '16px 36px 16px 24px',
                                    fontSize: "20px",
                                    fontFamily: "Poppins-Light",
                                }
                            }}
                            placeholder="Summary Of The Material*"
                            InputProps={{
                                // className: classes.input,
                                disableUnderline: true
                            }}
                            error={inputError?.mSummary === undefined || inputError?.mSummary === "" ? false : true}
                            helperText={inputError.mSummary}
                        />
                        <AddInstructorDropZone 
                            error={inputError.mPic}
                            type={'image'}
                            addInstructorProfile = {(acceptedFiles: any) => this.addLessonMaterialImage(mItem.id, acceptedFiles, item.id)}
                        />
                    </div>
                )
            })
        )
    }

    addRemoveTodo = (item: any) => {
        return(
            <>
                {
                    item?.todos?.length > 1 ?
                        <>
                            <IconButton 
                                style={{
                                    background: '#8991a4',
                                }}
                                onClick={() => this.removeNewTodos(item.id)}
                            >
                                <RemoveRoundedIcon 
                                    style={{color: '#fff'}}
                                />
                            </IconButton>
                            <IconButton 
                                style={{
                                    background: '#8991a4',
                                }}
                                onClick={() => this.addNewTodos(item.id)}
                            >
                                <img 
                                    height={30}
                                    width={30}
                                    src={require('../../assets/add.png')}
                                />
                            </IconButton>
                        </>
                        :
                        <IconButton 
                            style={{
                                background: '#8991a4',
                            }}
                            onClick={() => this.addNewTodos(item.id)}
                        >
                            <img 
                                height={30}
                                width={30}
                                src={require('../../assets/add.png')}
                            />
                        </IconButton>
                }
            </>
        )
    }

    getTodoForm = (item: any, inputError: any) => {
        return(
            <>
                {
                    item?.todos?.length && item.todos.map((tItem: any, index: any) => {
                        return(
                            <div
                                style={{
                                    marginTop: '15px'
                                }}
                                key={index}
                            >
                                <TextField
                                    fullWidth
                                    name="title"
                                    value={tItem.title}
                                    onChange={(e) => this.handleTodoChange(tItem.id, e, item.id)}
                                    InputProps={{
                                        // className: classes.input,
                                        disableUnderline: true,
                                        style:{
                                            height: '64px',
                                            borderRadius: '9px',
                                            border: '1px solid #e6e8f0',
                                            padding: '4px 36px 4px 24px',
                                            fontSize: "20px",
                                            fontFamily: "Poppins-Light",
                                            color: '#353535'
                                        }
                                    }}
                                    style={{
                                        fontSize: "20px",
                                        fontFamily: "Poppins-Light",
                                        marginTop: "10px",
                                    }}
                                    placeholder="Title*"
                                    error={inputError?.todoTitle === undefined || inputError?.todoTitle === "" ? false : true}
                                    helperText={inputError.todoTitle}
                                />
                                <TextField
                                    name="description"
                                    style={{ fontSize: "16px", fontFamily: "Poppins-Light", paddingTop: "10px" }}
                                    multiline={true}
                                    minRows={15}
                                    fullWidth
                                    value={tItem.description}
                                    onChange={(e) => this.handleTodoChange(tItem.id, e, item.id)}
                                    inputProps={{ 
                                        maxLength: 1250 ,
                                        style:{
                                            height: '200px',
                                            borderRadius: '9px',
                                            border: '1px solid rgba(0, 0, 0, 0.12)',
                                            padding: '16px 36px 16px 24px',
                                            fontSize: "20px",
                                            fontFamily: "Poppins-Light",
                                        }
                                    }}
                                    placeholder="Add to-do here*"
                                    InputProps={{
                                        // className: classes.input,
                                        disableUnderline: true
                                    }}
                                    error={inputError?.todoDesc === undefined || inputError?.todoDesc === "" ? false : true}
                                    helperText={inputError.todoDesc}
                                />
                            </div>
                        )
                    }) 
                }
            </>
        )
    }

    addRemoveQuestion = (item: any) => {
        return(
            <>
                {
                    item?.questions_attributes?.length > 1 ?
                        <>
                            <IconButton 
                                style={{
                                    background: '#8991a4',
                                }}
                                onClick={() => this.removeNewQuestions(item.id)}
                            >
                                <RemoveRoundedIcon 
                                    style={{color: '#fff'}}
                                />
                            </IconButton>
                            <IconButton 
                                style={{
                                    background: '#8991a4',
                                }}
                                onClick={() => this.addNewQuestions(item.id)}
                            >
                                <img 
                                    height={30}
                                    width={30}
                                    src={require('../../assets/add.png')}
                                />
                            </IconButton>
                        </>
                        :
                        <IconButton 
                            style={{
                                background: '#8991a4',
                            }}
                            onClick={() => this.addNewQuestions(item.id)}
                        >
                            <img 
                                height={30}
                                width={30}
                                src={require('../../assets/add.png')}
                            />
                        </IconButton>
                }
            </>
        )
    }

    getQuestionsForm = (item: any, inputError: any) => {
        return(
            <div key={item.id}>
                {
                    item?.questions_attributes?.length && item.questions_attributes.map((qItem: any, i: any) => {
                        return(
                            <>
                                <div
                                    style={{
                                        marginTop: '15px'
                                    }}
                                >
                                    <span
                                        style={{
                                            fontFamily: 'Poppins-SemiBold',
                                            fontSize: '16px',
                                            color: '#353535'
                                        }}
                                    >
                                        Question {i+1}
                                    </span>
                                    <TextField
                                        fullWidth
                                        name="question"
                                        value={qItem.question}
                                        onChange={(e) => this.handleQuestionChange(qItem.id, e, item.id)}
                                        InputProps={{
                                            // className: classes.input,
                                            disableUnderline: true,
                                            style:{
                                                height: '64px',
                                                borderRadius: '9px',
                                                border: '1px solid #e6e8f0',
                                                padding: '4px 36px 4px 24px',
                                                fontSize: "20px",
                                                fontFamily: "Poppins-Light",
                                                color: '#353535'
                                            }
                                        }}
                                        style={{
                                            fontSize: "20px",
                                            fontFamily: "Poppins-Light",
                                            marginTop: "10px",
                                        }}
                                        placeholder="Question Here*"
                                        error={inputError?.question === undefined || inputError?.question === "" ? false : true}
                                        helperText={inputError.question}
                                    />
                                    <TextField
                                        fullWidth
                                        name="description"
                                        value={qItem.description}
                                        onChange={(e) => this.handleQuestionChange(qItem.id, e, item.id)}
                                        InputProps={{
                                            // className: classes.input,
                                            disableUnderline: true,
                                            style:{
                                                height: '64px',
                                                borderRadius: '9px',
                                                border: '1px solid #e6e8f0',
                                                padding: '4px 36px 4px 24px',
                                                fontSize: "20px",
                                                fontFamily: "Poppins-Light",
                                                color: '#353535'
                                            }
                                        }}
                                        style={{
                                            fontSize: "20px",
                                            fontFamily: "Poppins-Light",
                                            marginTop: "10px",
                                        }}
                                        placeholder="Subheading for options to choose*"
                                        error={inputError?.description === undefined || inputError?.description === "" ? false : true}
                                        helperText={inputError.description}
                                    />
                                    <Grid spacing={1} container>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                name="option1"
                                                value={qItem.option1}
                                                onChange={(e) => this.handleQuestionChange(qItem.id, e, item.id)}
                                                InputProps={{
                                                    // className: classes.input,
                                                    disableUnderline: true,
                                                    style:{
                                                        height: '64px',
                                                        borderRadius: '9px',
                                                        border: '1px solid #e6e8f0',
                                                        padding: '4px 36px 4px 24px',
                                                        fontSize: "20px",
                                                        fontFamily: "Poppins-Light",
                                                        color: '#353535'
                                                    }
                                                }}
                                                style={{
                                                    fontSize: "20px",
                                                    fontFamily: "Poppins-Light",
                                                    marginTop: "10px",
                                                }}
                                                placeholder="Option 1*"
                                                error={inputError?.option1 === undefined || inputError?.option1 === "" ? false : true}
                                                helperText={inputError.option1}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                name="option2"
                                                value={qItem.option2}
                                                onChange={(e) => this.handleQuestionChange(qItem.id, e, item.id)}
                                                InputProps={{
                                                    // className: classes.input,
                                                    disableUnderline: true,
                                                    style:{
                                                        height: '64px',
                                                        borderRadius: '9px',
                                                        border: '1px solid #e6e8f0',
                                                        padding: '4px 36px 4px 24px',
                                                        fontSize: "20px",
                                                        fontFamily: "Poppins-Light",
                                                        color: '#353535'
                                                    }
                                                }}
                                                style={{
                                                    fontSize: "20px",
                                                    fontFamily: "Poppins-Light",
                                                    marginTop: "10px",
                                                }}
                                                placeholder="Option 2*"
                                                error={inputError?.option2 === undefined || inputError?.option2 === "" ? false : true}
                                                helperText={inputError.option2}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                name="option3"
                                                value={qItem.option3}
                                                onChange={(e) => this.handleQuestionChange(qItem.id, e, item.id)}
                                                InputProps={{
                                                    // className: classes.input,
                                                    disableUnderline: true,
                                                    style:{
                                                        height: '64px',
                                                        borderRadius: '9px',
                                                        border: '1px solid #e6e8f0',
                                                        padding: '4px 36px 4px 24px',
                                                        fontSize: "20px",
                                                        fontFamily: "Poppins-Light",
                                                        color: '#353535'
                                                    }
                                                }}
                                                style={{
                                                    fontSize: "20px",
                                                    fontFamily: "Poppins-Light",
                                                }}
                                                placeholder="Option 3*"
                                                error={inputError?.option3 === undefined || inputError?.option3 === "" ? false : true}
                                                helperText={inputError.option3}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                name="option4"
                                                value={qItem.option4}
                                                onChange={(e) => this.handleQuestionChange(qItem.id, e, item.id)}
                                                InputProps={{
                                                    // className: classes.input,
                                                    disableUnderline: true,
                                                    style:{
                                                        height: '64px',
                                                        borderRadius: '9px',
                                                        border: '1px solid #e6e8f0',
                                                        padding: '4px 36px 4px 24px',
                                                        fontSize: "20px",
                                                        fontFamily: "Poppins-Light",
                                                        color: '#353535'
                                                    }
                                                }}
                                                style={{
                                                    fontSize: "20px",
                                                    fontFamily: "Poppins-Light",
                                                }}
                                                placeholder="Option 4*"
                                                error={inputError?.option4 === undefined || inputError?.option4 === "" ? false : true}
                                                helperText={inputError.option4}
                                            />
                                        </Grid>
                                    </Grid>
                                    <TextField
                                        fullWidth
                                        name="correct_answer"
                                        value={qItem.correct_answer}
                                        onChange={(e) => this.handleQuestionChange(qItem.id, e, item.id)}
                                        InputProps={{
                                            // className: classes.input,
                                            disableUnderline: true,
                                            style:{
                                                height: '64px',
                                                borderRadius: '9px',
                                                border: '1px solid #e6e8f0',
                                                padding: '4px 36px 4px 24px',
                                                fontSize: "20px",
                                                fontFamily: "Poppins-Light",
                                                color: '#353535'
                                            }
                                        }}
                                        style={{
                                            fontSize: "20px",
                                            fontFamily: "Poppins-Light",
                                            marginTop: "10px",
                                        }}
                                        placeholder="Correct answer*"
                                        error={inputError?.correctAnswer === undefined || inputError?.correctAnswer === "" ? false : true}
                                        helperText={inputError.correctAnswer}
                                    />
                                </div>
                            </>
                        )
                    })
                }
            </div>
        )
    }

    addRemoveFaq = (item: any) => {
        return(
            <>
                {
                    item?.faqs?.length > 1 ?
                        <>
                            <IconButton 
                                style={{
                                    background: '#8991a4',
                                }}
                                onClick={() => this.removeNewFaqs(item.id)}
                            >
                                <RemoveRoundedIcon 
                                    style={{color: '#fff'}}
                                />
                            </IconButton>
                            <IconButton 
                                style={{
                                    background: '#8991a4',
                                }}
                                onClick={() => this.addNewFaqs(item.id)}
                            >
                                <img 
                                    height={30}
                                    width={30}
                                    src={require('../../assets/add.png')}
                                />
                            </IconButton>
                        </>
                        :
                        <IconButton 
                            style={{
                                background: '#8991a4',
                            }}
                            onClick={() => this.addNewFaqs(item.id)}
                        >
                            <img 
                                height={30}
                                width={30}
                                src={require('../../assets/add.png')}
                            />
                        </IconButton>
                }
            </>
        )
    }

    getFaqForm = (item: any, inputError: any) => {
        return(
            <>
                {
                    item?.faqs?.length && item.faqs.map((fItem: any, index: any) => {
                        return (
                            <div
                                style={{
                                    marginTop: '15px'
                                }}
                                key={index}
                            >
                                <TextField
                                    fullWidth
                                    name="question"
                                    value={fItem.question}
                                    onChange={(e) => this.handleFaqChange(fItem.id, e, item.id)}
                                    InputProps={{
                                        // className: classes.input,
                                        disableUnderline: true,
                                        style:{
                                            height: '64px',
                                            borderRadius: '9px',
                                            border: '1px solid #e6e8f0',
                                            padding: '4px 36px 4px 24px',
                                            fontSize: "20px",
                                            fontFamily: "Poppins-Light",
                                            color: '#353535'
                                        }
                                    }}
                                    style={{
                                        fontSize: "20px",
                                        fontFamily: "Poppins-Light",
                                        marginTop: "10px",
                                    }}
                                    placeholder="Question*"
                                    error={inputError?.faqQuestion === undefined || inputError?.faqQuestion === "" ? false : true}
                                    helperText={inputError.faqQuestion}
                                />
                                <TextField
                                    name="answer"
                                    style={{ fontSize: "16px", fontFamily: "Poppins-Light", paddingTop: "10px" }}
                                    multiline={true}
                                    minRows={15}
                                    fullWidth
                                    value={fItem.answer}
                                    onChange={(e) => this.handleFaqChange(fItem.id, e, item.id)}
                                    inputProps={{ 
                                        maxLength: 1250 ,
                                        style:{
                                            height: '200px',
                                            borderRadius: '9px',
                                            border: '1px solid rgba(0, 0, 0, 0.12)',
                                            padding: '16px 36px 16px 24px',
                                            fontSize: "20px",
                                            fontFamily: "Poppins-Light",
                                        }
                                    }}
                                    placeholder="Add FAQ’s here*"
                                    InputProps={{
                                        // className: classes.input,
                                        disableUnderline: true
                                    }}
                                    error={inputError?.faqAnswer === undefined || inputError?.faqAnswer === "" ? false : true}
                                    helperText={inputError.faqAnswer}
                                />
                            </div>
                        )
                    })
                }
            </>
        )
    }

    lessonListFunc = (lessonList: any) => {
        const { inputError, openSetting } = this.state;
        return (
            lessonList.length && lessonList.map((item: any, index: any) => {
                return (
                    <Container>
                        <div style={{ width: openSetting ? "calc(100% - 300px)" : "100%" }}>
                            <div
                                style={{
                                    marginTop: '15px',
                                    marginBottom: '15px',
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <span
                                    style={{
                                        fontFamily: 'Poppins-SemiBold',
                                        fontSize: '24px',
                                        color: '#353535'
                                    }}
                                >
                                    Lesson {index+1}
                                </span>
                                {
                                    index ?
                                    <IconButton 
                                        aria-label="delete"
                                        style={{
                                            background: palette.primary.main
                                        }}
                                        onClick={() => this.removeAdditionalForm(item.id)}
                                    >
                                        <DeleteIcon 
                                            style={{
                                                color: '#fff'
                                            }}
                                        />
                                    </IconButton>
                                    : null
                                }
                            </div>
                            <div
                                style={{
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    borderRadius: '8px',
                                    padding: '28px 48px 40px',
                                }}
                            >
                                <div>
                                    <span
                                        style={{
                                            fontFamily: 'Poppins-SemiBold',
                                            fontSize: '20px',
                                            color: '#353535'
                                        }}
                                    >
                                        Lesson Details
                                    </span>
                                </div>
                                {
                                    this.getLessonDetailsForm(item, inputError)
                                }
                            </div>
                            <div
                                style={{
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    borderRadius: '8px',
                                    padding: '28px 48px 40px',
                                    marginTop: '15px',
                                    minHeight: '346px',
                                    maxHeight: '346px',
                                    overflowY: 'scroll'
                                }}
                            >
                                <div>
                                    <span
                                        style={{
                                            fontFamily: 'Poppins-SemiBold',
                                            fontSize: '20px',
                                            color: '#353535'
                                        }}
                                    >
                                        Transcript
                                    </span>
                                </div>
                                {
                                    item?.transcript?.length && item.transcript.map((tItem: any, index: any) => {
                                        return (
                                            this.getTranscriptForm(tItem, index, inputError, item)
                                        )
                                    })
                                }
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: item?.transcript?.length < 2 ? 'flex-end' : 'space-between',
                                        marginTop: '15px'
                                    }}
                                >
                                    {
                                        this.addRemoveTrans(item)
                                    }
                                </div>
                            </div>
                            <div
                                style={{
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    borderRadius: '8px',
                                    padding: '28px 48px 40px',
                                    marginTop: '15px',
                                    minHeight: '346px',
                                    maxHeight: '346px',
                                    overflowY: 'scroll'
                                }}
                            >
                                <div>
                                    <span
                                        style={{
                                            fontFamily: 'Poppins-SemiBold',
                                            fontSize: '20px',
                                            color: '#353535'
                                        }}
                                    >
                                        Timestamp
                                    </span>
                                </div>
                                {
                                    item?.timestamp?.length && item.timestamp.map((sItem: any, index: any) => {
                                        return (
                                            this.getStampForm(sItem, index, inputError, item)
                                        )
                                    })
                                }
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: item?.timestamp?.length < 2 ? 'flex-end' : 'space-between',
                                        marginTop: '15px'
                                    }}
                                >
                                    {
                                        this.addRemoveStamp(item)
                                    }
                                </div>
                            </div>
                            <div
                                style={{
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    borderRadius: '8px',
                                    padding: '28px 48px 40px',
                                    marginTop: '15px'
                                }}
                            >
                                <div>
                                    <span
                                        style={{
                                            fontFamily: 'Poppins-SemiBold',
                                            fontSize: '20px',
                                            color: '#353535'
                                        }}
                                    >
                                        Lesson Material
                                    </span>
                                </div>
                                {
                                    this.getLessonMaterialForm(item, inputError)
                                }
                            </div>
                            <div
                                style={{
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    borderRadius: '8px',
                                    padding: '28px 48px 40px',
                                    marginTop: '15px'
                                }}
                            >
                                <div>
                                    <span
                                        style={{
                                            fontFamily: 'Poppins-SemiBold',
                                            fontSize: '20px',
                                            color: '#353535'
                                        }}
                                    >
                                        Todo
                                    </span>
                                </div>
                                {
                                    this.getTodoForm(item, inputError)
                                }
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: item?.todos?.length < 2 ? 'flex-end' : 'space-between',
                                        marginTop: '15px'
                                    }}
                                >
                                    {   
                                        this.addRemoveTodo(item)
                                    }
                                </div>
                            </div>
                            <div
                                style={{
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    borderRadius: '8px',
                                    padding: '28px 48px 40px',
                                    marginTop: '15px'
                                }}
                            >
                                <div>
                                    <span
                                        style={{
                                            fontFamily: 'Poppins-SemiBold',
                                            fontSize: '20px',
                                            color: '#353535'
                                        }}
                                    >
                                        Check Your Knowledge
                                    </span>
                                </div>
                                {
                                    this.getQuestionsForm(item, inputError)
                                }
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: item?.questions_attributes?.length < 2 ? 'flex-end' : 'space-between',
                                        marginTop: '15px'
                                    }}
                                >
                                    {   
                                        this.addRemoveQuestion(item)
                                    }
                                </div>
                            </div>
                            <div
                                style={{
                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                    borderRadius: '8px',
                                    padding: '28px 48px 40px',
                                    marginTop: '15px'
                                }}
                            >
                                <div>
                                    <span
                                        style={{
                                            fontFamily: 'Poppins-SemiBold',
                                            fontSize: '20px',
                                            color: '#353535'
                                        }}
                                    >
                                        FAQ's
                                    </span>
                                </div>
                                {
                                    this.getFaqForm(item, inputError)
                                }
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: item?.faqs?.length < 2 ? 'flex-end' : 'space-between',
                                        marginTop: '15px'
                                    }}
                                >
                                    {   
                                        this.addRemoveFaq(item)
                                    }
                                </div>
                            </div>
                        </div>
                    </Container>
                )
            })
        )
    }

    getCoinstructorList = (classes: any, inputError: any) => {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    borderRadius: '10px',
                    padding: '16px',
                    border: '1px solid rgba(82,92, 229, 0.12)',
                    minWidth: '300px'
                }}
            >
                <span
                    style={{
                        fontFamily: 'Poppins-Light',
                        fontSize: '20px',
                        color: '#353535',
                        marginRight: '10px',
                        minWidth: '150px'
                    }}
                >
                    Co Contributor*
                </span>
                <FormControl
                    variant="outlined"
                    fullWidth={true}
                >
                    <Select
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        multiple
                        IconComponent={KeyboardArrowDownIcon}
                        className={classes.select}
                        inputProps={{
                            classes: {
                                icon: classes.selectIcon,
                            },
                        }}
                        value={this.state.coInstructorName}
                        onChange={(event: any) => this.handleChangeCoInstructor(event)}
                        // input={<Input />}
                        renderValue={
                            this.state.coInstructorName?.length > 0
                                ? undefined
                                : () => <em>Placeholder</em>
                            }
                        // MenuProps={MenuProps}
                        >

                        {this.state.coInstructorList.length ? this.state.coInstructorList.map((item: any, index: any) => (
                            <MenuItem 
                                key={index} 
                                value={item.id}
                                style={{
                                    fontSize: '18px',
                                    fontFamily: 'Poppins-SemiBold'
                                }}
                            >
                                {item.attributes.name}
                            </MenuItem>
                        )): null
                        }
                    </Select>
                    {inputError.coInstructorName ? <FormHelperText style={{color: 'red'}}>Please select co-intructor</FormHelperText> : null}
                </FormControl>
            </div>
        )
    }

    addNewInstructor = () => {
        return (
            // @ts-ignore
            <Box
                style={{
                    marginLeft: '15px',
                    marginTop: '15px'
                }}
            >
                <Button
                    style={{
                        width: '160px',
                        fontSize: '16px',
                        fontFamily: 'Poppins-SemiBold',
                        height: '64px',
                        border: '1px solid #f27024',
                        padding: '16px 10px',
                        color: '#f27024',
                        textTransform: 'none',
                        cursor: 'pointer'
                    }}
                    onClick={() => this.showInstructorForm()}
                >
                    Add New
                </Button>
            </Box>
        )
    }

    coInstructorForm = (classes: any, inputError: any) => {
        return (
            <div
                style={{
                    borderRadius: '8px',
                    border: '1px solid rgba(82,92, 229, 0.12)',
                    padding: '0px 16px 16px',
                    marginBottom: '15px'
                }}
            >
                <div>
                    <AddInstructorDropZone 
                        type={'image'}
                        error={inputError.addProfilePic}
                        addInstructorProfile = {(acceptedFiles: any) => this.addInstructorProfilePic(acceptedFiles)}
                    />
                </div>
                <div>
                    <TextField
                        fullWidth
                        name="Instructor_Name"
                        id="InstructorName"
                        value={this.state.addInstructorName}
                        onChange={(e) => this.handleInstructorChange(e, "addInstructorName")}
                        InputProps={{
                            className: classes.input,
                            disableUnderline: true
                        }}
                        inputProps={{
                            min: 0, 
                            style: { 
                                textAlign: 'left', 
                                padding: '4px 36px 4px 24px',
                                color: '#353535',
                            }
                        }}
                        style={{
                            fontSize: "20px",
                            fontFamily: "Poppins-Light",
                            marginTop: "10px",
                            color: '#353535',
                        }}
                        placeholder="Name of the Contributor*"
                        error={inputError?.addInsName === undefined || inputError?.addInsName === "" ? false : true}
                        helperText={inputError.addInsName}
                    />
                    <TextField
                        fullWidth
                        name="Instructor_About"
                        id="InstructorAbout"
                        value={this.state.addInstructorAbout}
                        onChange={(e) => this.handleInstructorChange(e, "addInstructorAbout")}
                        InputProps={{
                            className: classes.input,
                            disableUnderline: true
                        }}
                        inputProps={{
                            min: 0, 
                            style: { 
                                textAlign: 'left',
                                padding: '4px 36px 4px 24px',
                                color: '#353535', 
                            }
                        }}
                        style={{
                            fontSize: "20px",
                            fontFamily: "Poppins-Light",
                            marginTop: "10px",
                            color: '#353535',
                        }}
                        minRows={6}
                        multiline={true}
                        placeholder="About the Contributor*"
                        error={inputError?.addInsAbout === undefined || inputError?.addInsAbout === "" ? false : true}
                        helperText={inputError.addInsAbout}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginTop: '15px',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                    }}
                >
                    <Button
                        style={{
                            width: '160px',
                            background: palette.primary.main,
                            color: '#ffffff',
                            height: '64px',
                            padding: '15px 12px',
                            fontFamily: 'Poppins-SemiBold',
                            fontSize: '16px',
                            borderRadius: '8px'
                        }}
                        onClick={() => this.addInstructor()}
                    >
                        {this.state.instructorAddLoading ? 'Adding...' : 'Save' }
                    </Button>
                    <Button
                        style={{
                            width: '160px',
                            background: '#ffffff',
                            color: '#8991a4',
                            height: '64px',
                            padding: '15px 12px',
                            fontFamily: 'Poppins-SemiBold',
                            fontSize: '16px',
                            borderRadius: '8px',
                            border: '1px solid #8991a4',
                            marginLeft: '20px'
                        }}
                        onClick={() => this.showInstructorForm()}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        )
    }

    getMainInstructorList = (classes: any) => {
        return (
            <FormControl
                variant="outlined"
                fullWidth={true}
            >
                {
                    userType == 'instructor' ?
                        // @ts-ignore
                        <Box
                            style={{
                                // height: '38px',
                                borderRadius: '6px',
                                border: 'solid 1px #e6e8f0',
                                padding: '15px 12px 15px 15px'
                            }}
                        >
                            <span
                                style={{
                                    fontSize: '18px',
                                    fontFamily: 'Poppins-SemiBold',
                                    color: palette.primary.main
                                }}
                            >
                                {userName}
                            </span>
                        </Box>
                    :
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            // disabled={userType == 'instructor' ? true : false}
                            value={this.state.selectedMainInstructor}
                            onChange={(e: any) => this.changeMainInstructor(e)}
                            // label="Age"
                            IconComponent={KeyboardArrowDownIcon}
                            className={classes.select}
                            inputProps={{
                                classes: {
                                    icon: classes.selectIcon,
                                },
                            }}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {
                                this.state.mainInstructorList.map((item: any, index: any) => {
                                    return (
                                        <MenuItem key={index} value={item[0]}>
                                            {item[1]}
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                }
            </FormControl>
        )
    }

    getDropzone = (inputError: any) => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} sm={6}>
                    <AddInstructorDropZone 
                        type={'image'}
                        error={inputError.previewImg}
                        addInstructorProfile = {(acceptedFiles: any) => this.addPreviewPic(acceptedFiles)}
                    />
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                    <AddInstructorDropZone 
                        type={'video'}
                        error={inputError.previewVideo}
                        addInstructorProfile = {(acceptedFiles: any) => this.addPreviewVideo(acceptedFiles)}
                    />
                </Grid>
            </Grid>
        )
    }

    getAppBar = (classes: any, openSetting: any) => {
        return (
            <AppBar position='fixed' className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        aria-label="open drawer"
                        edge="start"
                        onClick={this.handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div className={classes.logo}>
                        <img src={require('../../../../components/src/logo.svg')} height={70} />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'space-between',
                            padding: '10px'
                        }}
                    >
                        <div>
                            <Typography
                                style={{
                                    fontFamily: 'Poppins-SemiBold',
                                    fontSize: '25px',
                                    color: '#353535',
                                    marginTop: "25px"
                                }}
                                className={classes.welcome}
                            >
                                Add New
                            </Typography>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: "center"
                            }}
                        >
                            <Typography onClick={() => this.handleDraft()} style={{ cursor: "pointer", fontFamily: "Poppins-Bold", color: "grey", marginTop: "25px" }}>Save draft</Typography>
                            <Button variant="contained" onClick={() => this.handlePublish()} style={{ backgroundColor: "#F27024", color: "#ffffff", marginTop: "25px", marginLeft: '20px', textTransform: "initial", fontFamily: "Poppins-Bold" }}>Publish</Button>
                            <IconButton
                                onClick={() => this.hanldeSettingToggle()}
                                style={{
                                    border: '1px solid #bdbdbd',
                                    height: '38px',
                                    width: '38px',
                                    borderRadius: '8px',
                                    marginTop: '25px',
                                    marginLeft: '20px',
                                    backgroundColor: openSetting ? "#F27024" : "#ffffff"
                                }}
                            >
                                <img src={require('../../assets/settings-unselectednew.png')} height={'38px'} />
                            </IconButton>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    getAddNewLessonBtn = (openSetting: any) => {
        return (
            <Container>
                <div style={{ width: openSetting ? "calc(100% - 300px)" : "100%" }}>
                    <div 
                        style={{
                            minHeight: '1px',
                            background: '#d8dae5',
                            minWidth: '100%',
                            marginBottom: '15px',
                            marginTop: '15px'
                        }}
                    ></div>
                    <div>
                        <Button
                            style={{
                                width: '100%',
                                height: '64px',
                                borderRadius: '6px',
                                border: `1px solid ${palette.primary.main}`,
                                color: palette.primary.main,
                                fontSize: '16px',
                                fontFamily: 'Poppins-SemiBold',
                                marginBottom: '15px',
                                textTransform: 'none'
                            }}
                            onClick={() => this.addNewLessonForm()}
                        >
                            Add New Lesson
                        </Button>
                    </div>
                </div>
            </Container>
        )
    }
    getForm = (
        openSetting: any, 
        courseTitle: any, 
        classes: any, 
        inputError: any, 
        courseDesc: any, 
        coursePrice: any
    ) => {
        return (
            <Container >
                <div style={{ width: openSetting ? "calc(100% - 300px)" : "100%" }}>
                    <TextField
                        fullWidth
                        name="courseTitle"
                        id="courseTitle"
                        value={courseTitle}
                        onChange={(e) => this.handleCourseChange(e, "courseTitle")}
                        InputProps={{
                            className: classes.input,
                            disableUnderline: true
                        }}
                        inputProps={{min: 0, style: { textAlign: 'left' }}}
                        style={{
                            fontSize: "2rem",
                            fontFamily: "Poppins-Light",
                            marginTop: "10px"
                        }}
                        placeholder="Course Title*"
                        error={inputError?.courseTitle === undefined || inputError?.courseTitle === "" ? false : true}
                        helperText={inputError.courseTitle}
                    />
                    <TextField
                        fullWidth
                        name="courseDesc"
                        id="courseDesc"
                        value={courseDesc}
                        onChange={(e) => this.handleCourseChange(e, "courseDesc")}
                        InputProps={{
                            className: classes.input,
                            disableUnderline: true
                        }}
                        inputProps={{min: 0, style: { textAlign: 'left' }}}
                        style={{
                            fontSize: "1.5rem",
                            fontFamily: "Poppins-Light",
                            marginTop: "10px"
                        }}
                        minRows={6}
                        multiline={true}
                        placeholder="About the course*"
                        error={inputError?.courseDesc === undefined || inputError?.courseDesc === "" ? false : true}
                        helperText={inputError.courseDesc}
                    />
                    <div>
                        {
                            this.getDropzone(inputError)
                        }
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            marginTop: '15px',
                            marginBottom: '15px'
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item md={5} xs={12}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        borderRadius: '10px',
                                        padding: '16px',
                                        border: '1px solid rgba(82,92, 229, 0.12)',
                                        minWidth: '300px'
                                    }}
                                >
                                    <span
                                        style={{
                                            fontFamily: 'Poppins-Light',
                                            fontSize: '20px',
                                            color: '#353535',
                                            marginRight: '10px'
                                        }}
                                    >
                                        Contributor
                                    </span>
                                    {
                                        this.getMainInstructorList(classes)
                                    }
                                </div>
                            </Grid>
                            <Grid item md={5} xs={12}>
                                {
                                    this.getCoinstructorList(classes, inputError)
                                }
                            </Grid>
                            <Grid md={2} xs={12} item>
                                {
                                    this.addNewInstructor()
                                }
                            </Grid>
                        </Grid>
                    </div>
                    {
                        this.state.enableInstructorForm ?
                            this.coInstructorForm(classes, inputError)
                        :   null
                    }
                    {
                        this.coInstructorDetails()
                    }
                </div>
            </Container>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start

        const { classes } = this.props;
        const { 
            mobileOpen, 
            errorMsg, 
            blogList, 
            openAlert, 
            categoryList, 
            getCategoryLoading, 
            dataCreationMsg, 
            selectecCategory, 
            blogUniqueItem, 
            openSetting, 
            authorList, 
            authorLoading, 
            sort, 
            dateTime, 
            inputError,
            courseDesc,
            courseTitle,
            selectedMainInstructor,
            coInstructorName,
            lessonList,
            coursePrice,
            previewImg,
            previewVideo,
            createLoading,
            setPath
        } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <div>
                {
                    createLoading ?
                        <CustomLoader
                            open={createLoading}
                        />
                    :   null
                }
                {/* @ts-ignore */}
                {openAlert ? (
                    <AlertModal
                        open={openAlert}
                        data={dataCreationMsg ? dataCreationMsg : errorMsg}
                        onClose={() => this.closeAlertBox()}
                        redirectUri={setPath ? 'AddNewCourses' : 'LetsLearn'}
                        navigation={this.props.navigation}
                    />
                ) : null}

                <DashboardDrawer
                    mobileOpen={mobileOpen}
                    handleDrawerToggle={this.handleDrawerToggle}
                    // @ts-ignore
                    navigation={this.props.navigation}
                />
                {
                    // @ts-ignore
                    openSetting && <DashboardSetting
                        handleDrawerToggle={this.handleDrawerToggle}
                        // @ts-ignore
                        navigation={this.props.navigation}
                        categoryList={categoryList}
                        authorList={authorList}
                        authorLoading={authorLoading}
                        handleSelectCategory={this.handleCategorySelect}
                        selectedCategory={selectecCategory}
                        errorMsg={errorMsg}
                        catValidation={this.state.catValidation}
                        categoryLoading={getCategoryLoading}
                        handleBgImgChange={this.handleBgImgChange}
                        handleSortChange={this.handleSortChange}
                        sort={sort}
                        dateTime={dateTime}
                        handleDateTimechange={this.handleDateTimeChange}
                        getBold={this.getBold}
                        getUnderlined={this.getUnderlined}
                        getItalic={this.getItalic}
                        getAlignment= {this.getAlignment}
                        type={'course'}
                        from={'course'}
                        othersName={this.state.othersName}
                        handleSelectOthers={this.handleSelectOthers}
                    />
                }
                {/* @ts-ignore */}
                <Box
                    className={classes.appBarWrap}
                >
                    {
                        this.getAppBar(classes, openSetting)
                    }
                    {/* @ts-ignore */}
                    {!this.state.nextPage && <>
                        <div style={{ position: 'relative', height: "5rem", boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)', width: openSetting ? "calc(100% - 300px)" : "100%" }}>
                            {courseTitle && previewImg.length && previewVideo.length && courseDesc && selectedMainInstructor && coInstructorName.length ? 
                                <Button 
                                    variant="outlined" 
                                    style={{ 
                                        position: "absolute", 
                                        right: "25px", 
                                        top: "23px", 
                                        padding: "5px 25px", 
                                        color: "#F27024", 
                                        border: "1px solid #F27024", 
                                        textTransform: "initial", 
                                        fontFamily: "Poppins-SemiBold" 
                                    }} 
                                    onClick={() => this.setState({ nextPage: true })}
                                >
                                    Next
                                </Button> 
                                : ""
                            }
                        </div>
                        {
                            this.getForm(openSetting, courseTitle, classes, inputError, courseDesc, coursePrice)
                        }
                    </>}
                    {this.state.nextPage && <>
                        <div style={{ position: 'relative', height: "5rem", boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)', width: openSetting ? "calc(100% - 300px)" : "100%" }}>
                            {blogList[0]?.heading?.text && blogList[0].para?.text && blogUniqueItem?.bgImg?.length ? <Button variant="outlined" onClick={() => this.handlePreviewOpen()} style={{ position: "absolute", right: "25px", top: "23px", color: "#F27024", border: "1px solid #F27024", textTransform: "initial", fontFamily: "Poppins-SemiBold" }}>Preview</Button> : ""}
                            <Button variant="outlined" style={{ position: "absolute", right: '20px', top: '23px', color: "#F27024", border: "1px solid #F27024", textTransform: "initial", fontFamily: "Poppins-SemiBold" }} onClick={() => this.setState({ nextPage: false })}>Go Back</Button>
                        </div>
                        {
                            this.lessonListFunc(lessonList)
                        }
                        {
                            this.getAddNewLessonBtn(openSetting)
                        }
                    </>}

                </Box>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// export default withStyles(styles)(LetsRead);
export default withTheme(
    withStyles((theme: Theme) =>
        createStyles({
            select: {
                color: palette.primary.main,
                fontSize: '18px',
                fontFamily: 'Poppins-SemiBold',
            },
            selectIcon: {
                fill: palette.primary.main,
                fontSize: '30px'
            },
            logo: {
                marginRight: theme.spacing(2),
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            menuButton: {
                marginRight: theme.spacing(2),
                color: palette.primary.main,
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            appBar: {
                background: '#fff',
                boxShadow: 'none',
                [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${drawerWidth}px)`,
                    marginLeft: drawerWidth,
                    background: '#fff',
                    boxShadow: 'none'
                },
            },
            appBarWrap: {
                [theme.breakpoints.up('md')]: {
                    width: `calc(100% - ${drawerWidth}px)`,
                    marginLeft: drawerWidth,
                },
            },
            welcome: {
                [theme.breakpoints.down('sm')]: {
                    display: 'none',
                },
            },
            name: {
                fontSize: '24px',
                [theme.breakpoints.down('sm')]: {
                    marginTop: '20px',
                    fontSize: '16px'
                },
            },
            tabsBox: {
                // flex: 1,
                // flexDirection: 'row',
                // justifyContent: 'space-between',
                width: '100%'
            },
            tabs_main: {
                "& .MuiTabs-indicator": {
                    backgroundColor: palette.primary.main,
                    borderRadius: '1.5px',
                    height: '4px',
                },
                "& .Mui-selected": {
                    color: palette.primary.main
                }
            },
            headingTabs: {
                fontSize: '24px',
                fontWeight: 600,
                fontFamily: palette.fontFamily.main,
                width: "25%",
                textTransform: 'inherit',
                // minWidth: '50% !important'
            },
            lastTableRow: {
                '&:last-child td, &:last-child th': { border: 0 }
            },
            triangle: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 1px 4px rgba(0,0,0,0.03))',
                marginTop: "49px",
                marginLeft: "12px",
                borderRadius: "10px",
                '& .MuiAvatar-root': {
                    width: '32px ',
                    height: '32px',
                    marginLeft: '-0.5px ',
                    marginRight: '1px',
                },
                '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: '0px',
                    right: '20px ',
                    width: '15px',
                    height: '15px ',
                    backgroundColor: '#ffffff',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                },
            },
            root: {
                color: "#F27024 !important",
                "& .Mui-selected": {
                    background: "#F27024",
                    color: "white",
                    fontWeight: "bold",
                },
                ul: {
                    "& .MuiPaginationItem-root": {
                        color: "#F27024"
                    }
                }
            },
            modalStyle: {
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '496px',
                height: "200px",
                backgroundColor: '#ffffff',
                borderRadius: '6px',
                boxShadow: '24px',
                padding: '10px 5px',
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            previewModalStyle: {
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '796px',
                maxHeight: "80vh",
                backgroundColor: '#ffffff',
                borderRadius: '6px',
                boxShadow: '24px',
                padding: '20px',
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
            menuItemStyle: {
                fontFamily: 'Poppins-Light',
                '&:hover': {
                    textAlign: "right",
                    color: '#d53636',
                    backgroundColor: '#ffffff',
                }
            },
            input: {
                width: "100%",
                height: "100%",
                border: "1px solid rgba(82,92, 229, 0.12)",
                borderRadius: "7px",
                textDecoration: "none",
                fontSize: "1em",
                fontFamily: "Poppins-Light",
                backgroundColor: "white",
                color: '#000 !important',
                padding: "5px 1rem", // 
                "& > .MuiInputBase-input": {
                    height: "100%",
                },
                "&  .MuiFormHelperText-root.Mui-error": { 
                    fontFamily: "Poppins-Light",
                  },
            },


        }))(AddNewCourses)
)
// Customizable Area End
